@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    font-family: Raleway, Calibri, Helvetica, sans-serif;
    width: 100%;
    margin: 0;
    background-color: white;
}

a {
    color: black;
}

.nav-bar {
    list-style-type: none;
    margin: 0 5px;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
}

.nav-bar>li {
    display: block;
    float: left;
    margin: 5px 1px;
    padding: 4px;
    border-radius: 3px;
    font-size: 16px;
}

.nav-bar>li:nth-of-type(odd):hover {
    background-color: #E8E8E8;
}

.nav-bar>li>a {
    text-decoration: none;
    color: black;
}

.nav-bar>li:last-of-type {
    text-decoration: underline;
}

h1 {
    font-size: 20px;
    color: #457492;
}

div.section-container>section {
    padding: 20px 30px;
    margin: 0 auto;
    max-width: 805px;
    font-size: 20px;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
}

.block {
    background-color: #f1f4f7;
    margin: 0 auto;
    border-radius: 10px;
    padding: 20px;
    margin: 6px 0;
}

.block-heading {
    font-size: 22px;
    font-weight: bold;
}

.semi-block-container {
    display: flex;
    flex-wrap: wrap;
}

.semi-block-container>div {
    width: 45.5%;
    flex-basis: 365px;
    flex-grow: 9999;
    background-color: #f1f4f7;
    margin: 3px 2.5px;
    border-radius: 10px;
    padding: 15px;
}

.material-symbols-outlined {
    font-size: 15px;
}